/* home style ===================================================*/
.disabled {
  /* pointer-events: none; */
  background-color: red;
}

.blogOverlayDisabled {
  background: rgba(0, 0, 0, 0.692);
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
}

.blogOverlayDisabled::after {
  color: red;
  content: 'NEED ACCESS';
  position: absolute;
  font-weight: bold;
  width: 100%;
  bottom: 50%;
  top: 50%;
  text-align: center;
  margin: auto 0px;
  transform: rotate(25deg);
  font-size: 16px;
  letter-spacing: 2px;
}



.boxTitleBlogDisabled {
  padding: 20px 20px;
  color: white;
}

.boxTitleBlogDisabled h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #ccc;
}

.boxTitleBlogDisabled p {
  font-weight: normal;
  font-size: 13px;
  color: #aaa;
}

.boxTitleBlogDisabled span {
  font-weight: normal;
  font-size: 12px;
  color: #777;
}

.logoNameNav {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.loginButton {
  font-size: 15px;
  font-weight: bold;
  color: black;
  background-color: white;
  padding: 4px 16px;
  border-radius: 6px;
}

.headerHome {
  max-width: 100%;
  position: relative;
  color: white;
}

.backgoroundHomeHeader {
  width: 100%;
  object-fit: cover;
  height: 96vh;
}

.contentHomeHeaderBox {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translate(0%, -50%);
}

.logoNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  margin-bottom: 40px;
}

.myNameAndRole {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.roleTitle {
  font-size: 17px;
  font-weight: bold;
  line-height: 0.9;
}

.roleSubtitle {
  font-size: 15px;
  color: #ccc;
}

.boxTitleHeader {
  margin-bottom: 60px;
  max-width: 750px;
}

.boxTitleHeader h1 {
  font-weight: 500;
  font-size: 50px;
  line-height: 1;
  margin-bottom: 4px;
}

.boxTitleHeader h2 {
  font-size: 17px;
  color: #ccc;
}

.socialMediaHeaderBox {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

/* home style ===================================================*/

/* login style ===================================================*/

.loginSection {
  width: 100%;
  height: 72.85vh;
  background-color: #051012;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}

.loginBox {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: space-around;
}

.loginHeader {
  font-size: 45px;
  font-weight: bold;
}

.loginBox label {
  font-size: 17px;
  font-weight: bold;
}

.loginBox button {
  background-color: #0082FB;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 20;
  margin-top: 20px;
  min-width: 100%;
}

.formBox input[type=text],
input[type=password] {
  width: 100%;
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  margin-top: 5px;
}

.imageLoginBox {
  width: 60%;
  object-fit: cover;
}

.loginNoteHeader {
  font-size: 16px;
  font-weight: bold;
}

.loginNote {
  font-size: 14px;
  margin-top: 4px;
  max-width: 70%
}

.userNotFound {
  font-size: 15px;
  font-weight: bold;
  color: red;
  margin-top: 10px;
}

/* login style ===================================================*/


/* about style ===================================================== */

.aboutSection {
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.imageAboutBox {
  width: 45%;
}

.aboutBoxText {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: #031316;
  color: white;
  background-image: url("../public/gallery/bg.jpg");
}

.headerAbout {
  max-width: 700px;
}

.headerAbout h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.headerAbout h2 {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.headerAbout p {
  font-size: 16px;
}


/* about style ===================================================== */


/* about me style ===================================================== */

.boxContentAbout {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #031316;
  color: #fff;
}

.imageprofile {
  width: 50%;
  min-height: 450px;
}

.boxDescriptionMe {
  width: 50%;
  background-image: url('../public/gallery/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 450px;
  padding: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.boxDescriptionMe h1 {
  font-size: 32px;
  font-weight: bold;
}

.boxDescriptionMe span {
  font-size: 15px;
  color: #ccc;
}

.imageVerticalContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #05171c;
}

.boxWhoMe {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.boxDoingAbout {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.boxVerticalImage {
  width: 25%;
  height: 545px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.boxVerticalImage img {
  width: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
  transform-origin: 50% 50%;
  z-index: -10;
}

.boxVerticalImage img:hover {
  opacity: 0.4;
  transform: scale(1.1);
  width: 100%;
  cursor: pointer;
}

.filmName {
  position: absolute;
  width: 90%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.filmTitle {
  color: white;
  font-size: 24px;
  font-weight: bolder;
  height: auto;
}

.filmSubitle {
  color: #ccc;
  font-size: 14.5px;
  font-weight: 500;
}



/* about me style ===================================================== */


/* gallery style ===================================================== */

.photoContainer {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #031316;
}

.boxPhoto {
  max-width: 33.3%;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;
}

.imageItem {
  width: 100%;
  transition: transform 0.4s ease;
  transform-origin: 50% 50%;
}

.imageItem:hover {
  transform: scale(1.1);
  width: 100%;
  cursor: pointer;
}

/* gallery style ===================================================== */


/* portfolio style ===================================================== */
.boxPortfolioImageGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cardImageContainer {
  width: 30%;
  object-fit: cover;
  color: black;
  margin: 30px;
  border-radius: 12px;
  box-shadow: 1px 3px 10px 4px #acb1b60e;
  z-index: 4;
}

.cardImageContainer img {
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 650px;
}


.cardDescription {
  border-radius: 0px 0px 8px 8px;
}

.cardDescription h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #ccc;
}

.cardDescription p {
  font-size: 14px;
  color: #777;
}

/* portfolio style ===================================================== */


/* Blog style ===================================================== */

.backgroundBlog {
  background-color: #061b1e;
}

.boxListBlog {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 10;
  justify-content: center;
}

.boxBlogContainer {
  width: 30%;
  margin: 6px;
  border-radius: 20px;
  background-color: #05171c;
  z-index: 4;
  box-shadow: 2px 2px 2px 1px #25434b2a;

}

.boxBlogContainerDisabled {
  width: 30%;
  margin: 6px;
  border-radius: 20px;
  background-color: #0d272f;
  z-index: 4;
  box-shadow: 2px 2px 2px 1px #25434b2a;
}

.containerBoxImageBlog {
  position: relative;
}

.blogOverlay {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
}

.blogOverlay:hover {
  opacity: 0;
}

.boxBlogContainer img {
  border-radius: 20px 20px 0px 0px;
  max-width: 100%;
  object-fit: 'cover';
  transition: transform 0.4s ease;
}

.boxBlogContainerDisabled img {
  border-radius: 20px 20px 0px 0px;
  max-width: 100%;
  object-fit: 'cover';
  transition: transform 0.4s ease;
}



.boxTitleBlog {
  padding: 20px 20px;
}

.boxTitleBlog h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #ccc;
}

.boxTitleBlog p {
  font-weight: normal;
  font-size: 13px;
  color: #aaa;
}

.boxTitleBlog span {
  font-weight: normal;
  font-size: 12px;
  color: #777;
}

/* Blog style ===================================================== */


/* Login style ===================================================== */
.loginContainer {
  background-color: #031316;
}

/* Login style ===================================================== */


/* detail project style ===================================================== */
.detailProjectContainer {
  z-index: 10;
}

.boxContentProject {
  padding: 40px 0px 0px 0px;
  width: 100%;
  background-color: #031316;
  display: flex;
  flex-direction: column;
  z-index: 4;
  color: white;
}

.boxContentProject img {
  width: 65%;
  box-shadow: 0px 0px 10px 6px #133b4347;
  z-index: 4;
}

.boxDescriptionProject {
  margin: 50px 150px 20px 150px;
  z-index: 4;
}

.boxDescriptionProject h1 {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2;
}

.boxDescriptionProject h2 {
  font-size: 17px;
  color: #ccc;
  font-weight: normal;
}

.boxProjectImageGallery {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #031316;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.imageGalleryProject {
  max-width: 19.5%;
  padding: 2.5px;
  background-color: #05171c;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;
}

.stillImageContent {
  width: 100%;
  transition: transform 0.4s ease;
  transform-origin: 50% 50%;
}

.stillImageContent:hover {
  transform: scale(1.1);
  width: 100%;
  cursor: pointer;
}


/* detail project style ===================================================== */


/* Footer style ===================================================== */

.footerContainer {
  max-width: 100%;
  padding: 50px 30px;
  background-color: #05171c;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  z-index: 3;
}

.boxTextFooter {
  padding: 0px 80px;
  width: 540px;
}

.boxTextFooter p {
  font-size: 14px;
}

.boxSocialMedia {
  display: flex;
  flex-direction: row;
}

.boxCredit {
  width: 100%;
  color: white;
  background-color: #031316;
  text-align: center;
  padding: 20px 0px;

}

.boxCredit p {
  font-size: 14px;
  font-weight: bold;
}

.boxCredit span {
  font-size: 12px;
}


.logoNameFooter {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.titleSosmed {
  font-size: 15px;
  color: white;
}

/* Footer style ===================================================== */





/* ECT style ===================================================== */

.miniBox {
  width: 60px;
  height: 8px;
  background-image: linear-gradient(to right, #0082FB, #92A8B0);
}

.customOutlineButton {
  color: white;
  font-weight: bold;
  font-size: 17px;
  border: 2px solid white;
  padding: 16px 50px;
  border-radius: 50px;
  text-align: center;
}

.customOutlineButton:hover {
  color: black;
  background-color: white;
}

.backgroundPage {
  max-width: 100%;
  background-color: #05171c;
  text-align: center;
  margin: auto;
  padding: 30px 0px;
  color: white;
}

.titleName {
  font-size: 32px;
  font-weight: bolder;
}

.subtitleName {
  font-size: 17px;
}

.link {
  color: #0082FB;
  font-weight: 600;
}

.headerTextSection {
  max-width: 600px;
  color: white;
}

.headerTextSection h1 {
  font-size: 65px;
  font-weight: bold;
}

.headerTextSection p {
  font-size: 18px;
  font-weight: normal;
}

.boxContentPortfolio {
  display: flex;
  flex-direction: column;
  background-color: #031316;
  color: #fff;
  padding: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}

.boxNavigateDetail {
  padding: 12px 22px;
  color: #000000;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 12px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.boxTextContent h6 {
  font-weight: bold;
  font-size: 18px;
}

.boxTextContent p {
  font-weight: normal;
  font-size: 14px;
}

.buttonNavigate {
  background-color: #ccc;
  padding: 8px 20px;
  font-size: 15px;
  border-radius: 6px;
  color: black;
  font-weight: bold;
}


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}



.headerModalBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 30px;
}

.headerTitlePadding {
  padding: 20px;
}

.headerTitleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  justify-items: flex-start;
}

.headerTitleModal h3 {
  font-weight: bold;
  font-size: 25px;
}

.headerTitleModal p {
  font-weight: normal;
  font-size: 15px;
}

.closeButton {
  color: white;
  font-size: 30px;
}

.paddingModal {
  padding: 5%;
}

.imageSlider {
  display: flex;
  flex-direction: row;
}

.backImageButton {
  width: 15%;
  height: 50%;
  background-color: none;
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  margin: auto 0;
  opacity: 0;
}

.nextImageButton {
  width: 15%;
  height: 50%;
  background-color: none;
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 0;
  margin: auto 0;
  opacity: 0;
}

.backImageButton:focus {
  opacity: 0;
}

.nextImageButton:focus {
  opacity: 0;
}

.backImageButton:active {
  opacity: 0;
}

.nextImageButton:active {
  opacity: 0;
}

/* ECT style ===================================================== */


/* responsive style ===================================================== */

@media only screen and (width < 306px) {

  /* home style ===================================================== */

  .logoNameNav {
    font-size: 15px;
  }

  .backgoroundHomeHeader {
    height: 90vh;
  }

  .boxTitleHeader {
    max-width: 400px;
  }

  .boxTitleHeader h1 {
    font-size: 28px;
  }

  .boxTitleHeader h2 {
    font-size: 15px;
  }


  /* home style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */

  /* Login style ===================================================== */

  .loginSection {
    padding: 0px;
    height: 59.99vh;
    margin: 0;
  }

  .loginBox {
    padding: 20px;
    margin: 0px;
    width: 100%;
  }

  .loginHeader {
    font-size: 17px;
    font-weight: bold;
  }

  .loginBox label {
    font-size: 13px;
    font-weight: bold;
  }

  .loginBox button {
    padding: 8px 8px;
    font-size: 14px;
  }

  .formBox input[type=text],
  input[type=password] {
    width: 100%;
    padding: 4px 10px;
  }

  .imageLoginBox {
    display: none;
  }

  .loginNoteHeader {
    font-size: 12px;
  }

  .loginNote {
    font-size: 10px;
    margin: 0;
    line-height: 20px;
  }

  /* Login style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */

  /* About style ===================================================== */

  .aboutSection {
    flex-direction: column;
  }

  .imageAboutBox {
    display: none;
  }

  .aboutBoxText {
    width: 100%;
    padding: 30px;
  }

  /* About style ===================================================== */

  /* About me style ===================================================== */

  .boxContentAbout {
    flex-direction: column;
    justify-content: flex-start;
  }

  .boxWhoMe {
    flex-direction: column-reverse;
  }

  .boxDoingAbout {
    flex-direction: column;
  }

  .imageprofile {
    width: 100%;
    min-height: 200px;
  }

  .boxDescriptionMe {
    width: 100%;
  }

  .boxVerticalImage {
    width: 100%;
  }

  /* About me style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */

  /* gallery style ===================================================== */

  .boxPhoto {
    max-width: 100%;
  }

  /* gallery style ===================================================== */


  /* portfolio style ===================================================== */

  .boxContentPortfolio {
    padding: 10px;
  }

  .boxPortfolioImageGallery {
    flex-direction: column;
    align-items: center;
  }

  .cardImageContainer {
    width: 90%;
    margin: 0px;
  }

  .cardImageContainer img {
    height: 200px;
  }

  .boxNavigateDetail {
    padding: 12px 12px;
  }

  .buttonNavigate {
    padding: 6px 8px;
    font-size: 10px;
  }

  .cardDescription {
    padding: 0px;
  }

  .cardDescription h1 {
    font-size: 13px;
  }

  .cardDescription p {
    font-size: 10px;
  }

  /* portfolio style ===================================================== */


  /* Blog style ===================================================== */


  .boxBlogContainer {
    width: 100%;
  }

  .boxBlogContainerDisabled {
    width: 100%;
  }

  /* Blog style ===================================================== */



  /* detailProject style ===================================================== */

  .boxContentProject {
    padding: 10px;
  }

  .boxContentProject img {
    width: 90%;
  }

  .boxDescriptionProject {
    margin: 20px 5px;
  }

  .boxDescriptionProject h1 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
  }

  .boxDescriptionProject h2 {
    margin-bottom: 8px;
    font-size: 15px;
  }

  .boxDescriptionProject p {
    font-size: 14px;
  }

  .boxProjectImageGallery {
    padding-bottom: 10px;
  }

  .imageGalleryProject {
    max-width: 45%;
  }

  /* detailProject style ===================================================== */


  /* footer style ===================================================== */

  .footerContainer {
    flex-direction: column;
    text-align: center;
  }

  .boxTextFooter {
    margin-top: 10px;
    max-width: 400px;
  }

  .boxContainerSocialMedia {
    margin-top: 10px;
  }

  .boxSocialMedia {
    justify-content: center;
  }

  /* footer style ===================================================== */

  /* ETC style ===================================================== */

  .backgroundPage .titleName {
    font-size: 20px;
  }

  .backgroundPage .subtitleName {
    font-size: 15px;
  }

  .customOutlineButton {
    font-size: 14px;
    padding: 14px 20px;
  }

  .headerTitlePadding {
    padding: 10px;
  }

  .headerTitleModal h3 {
    font-weight: bold;
    font-size: 10px;
    line-height: 1.2;
  }

  .headerTitleModal p {
    font-weight: normal;
    font-size: 8px;
  }

  .closeButton {
    font-size: 15px;
  }

  .imageSlider {
    max-width: 80%;
    object-fit: cover;
    color: black;
    background-color: white;
  }

  .modalSize {
    padding: 0px 40px;
    width: 100%;
  }

  #modalSizeSlider {
    padding: 40%;
  }

  .backImageButton {
    top: 40px;
    left: 20px;
    height: 40%;
  }

  .nextImageButton {
    top: 40px;
    right: 20px;
    height: 40%;
  }

  /* ETC style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */
}

@media only screen and (width >=306px) and (width < 767px) {
  /* ============================================================================================================== */
  /* ============================================================================================================== */

  /* home style ===================================================== */

  .logoNameNav {
    font-size: 15px;
  }

  .backgoroundHomeHeader {
    height: 90vh;
  }

  .boxTitleHeader {
    max-width: 400px;
  }

  .boxTitleHeader h1 {
    font-size: 28px;
  }

  .boxTitleHeader h2 {
    font-size: 15px;
  }

  /* home style ===================================================== */

  /* login style ===================================================== */


  .loginSection {
    padding: 0px;
    height: 59.99vh;
    margin: 0;
  }

  .loginBox {
    padding: 20px;
    margin: 0px;
    width: 100%;
  }

  .loginHeader {
    font-size: 25px;
    font-weight: bold;
  }

  .loginBox label {
    font-size: 15px;
    font-weight: bold;
  }

  .loginBox button {
    padding: 8px 8px;
    font-size: 14px;
  }

  .formBox input[type=text],
  input[type=password] {
    width: 100%;
    padding: 4px 10px;
  }

  .imageLoginBox {
    display: none;
  }

  .loginNoteHeader {
    font-size: 14px;
  }

  .loginNote {
    font-size: 12px;
    margin: 0;
    line-height: 20px;
  }

  /* login style ===================================================== */

  /* About style ===================================================== */

  .aboutSection {
    flex-direction: column;
  }

  .imageAboutBox {
    display: none;
  }

  .boxWhoMe {
    flex-direction: column-reverse;
  }

  .boxDoingAbout {
    flex-direction: column;
  }

  .aboutBoxText {
    width: 100%;
  }

  .boxDescriptionMe {
    width: 100%;
  }

  .imageprofile {
    width: 100%;
    min-height: 200px;
  }


  /* About style ===================================================== */

  /* About me style ===================================================== */

  .boxVerticalImage {
    width: 50%;
  }

  /* About me style ===================================================== */

  /* footer style ===================================================== */

  .footerContainer {
    flex-direction: column;
    text-align: center;
  }

  .boxTextFooter {
    margin-top: 10px;
  }

  .boxContainerSocialMedia {
    margin-top: 10px;
  }

  .boxSocialMedia {
    justify-content: center;
  }

  /* footer style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */

  /* Gallerry style ===================================================== */
  .boxPhoto {
    max-width: 50%;
  }

  /* Gallerry style ===================================================== */

  /* portfolio style ===================================================== */


  .boxContentPortfolio {
    padding: 10px;
  }

  .boxPortfolioImageGallery {
    flex-direction: column;
    align-items: center;
  }

  .cardImageContainer {
    width: 90%;
    margin: 10px;
  }

  .cardImageContainer img {
    height: 350px;
  }

  .boxNavigateDetail {
    padding: 12px 12px;
  }

  .buttonNavigate {
    padding: 6px 8px;
    font-size: 10px;
  }

  .cardDescription {
    padding: 0px;
  }

  .cardDescription h1 {
    font-size: 13px;
  }

  .cardDescription p {
    font-size: 10px;
  }

  /* portfolio style ===================================================== */

  /* Blog style ===================================================== */


  .boxBlogContainer {
    width: 85%;
  }

  .boxBlogContainerDisabled {
    width: 85%;
  }

  /* Blog style ===================================================== */


  /* detailProject style ===================================================== */

  .boxContentProject {
    padding: 30px;
  }

  .boxContentProject img {
    width: 90%;
  }

  .boxDescriptionProject {
    margin: 20px 5px;
  }

  .boxDescriptionProject h1 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
  }

  .boxDescriptionProject h2 {
    margin-bottom: 8px;
    font-size: 15px;
  }

  .boxDescriptionProject p {
    font-size: 14px;
  }

  .boxProjectImageGallery {
    padding-bottom: 10px;
  }

  .imageGalleryProject {
    max-width: 45%;
  }


  /* detailProject style ===================================================== */

  /* ETC style ===================================================== */

  .customOutlineButton {
    font-size: 12px;
    padding: 12px 16px;
  }

  .headerTitlePadding {
    padding: 10px;
  }

  .headerTitleModal h3 {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.3;
  }

  .headerTitleModal p {
    font-weight: normal;
    font-size: 9px;
  }

  .closeButton {
    font-size: 18px;
  }

  /* ETC style ===================================================== */

  /* ============================================================================================================== */
  /* ============================================================================================================== */
}

@media only screen and (width >=768px) and (width < 992px) {


  /* login style ===================================================== */


  .loginSection {
    padding: 0px;
    height: 72.87vh;
    margin: 0;
  }

  .loginBox {
    padding: 20px;
    margin: 0px;
    width: 100%;
  }

  .loginHeader {
    font-size: 25px;
    font-weight: bold;
  }

  .loginBox label {
    font-size: 15px;
    font-weight: bold;
  }

  .loginBox button {
    padding: 8px 8px;
    font-size: 14px;
  }

  .formBox input[type=text],
  input[type=password] {
    width: 100%;
    padding: 4px 10px;
  }

  .loginNoteHeader {
    font-size: 14px;
  }

  .loginNote {
    font-size: 12px;
    margin: 0;
    line-height: 20px;
  }

  /* login style ===================================================== */

  /* portfolio style ===================================================== */
  .boxContentPortfolio {
    padding: 10px;
  }

  .boxPortfolioImageGallery {
    align-items: center;
  }

  .cardImageContainer {
    width: 45%;
    margin: 10px;
  }

  .cardImageContainer img {
    height: 450px;
  }

  .boxNavigateDetail {
    padding: 12px 12px;
  }

  .buttonNavigate {
    padding: 6px 8px;
    font-size: 10px;
  }

  .cardDescription {
    padding: 0px;
  }

  .cardDescription h1 {
    font-size: 13px;
  }

  .cardDescription p {
    font-size: 10px;
  }

  /* portfolio style ===================================================== */

  /* About me style ===================================================== */

  .boxVerticalImage {
    width: 35%;
  }

  /* About me style ===================================================== */


  /* Blog style ===================================================== */


  .boxBlogContainer {
    width: 90%;
  }

  .boxBlogContainerDisabled {
    width: 90%;
  }

  /* Blog style ===================================================== */


  /* detailProject style ===================================================== */

  .boxContentProject {
    padding: 30px;
  }

  .boxContentProject img {
    width: 90%;
  }

  .boxDescriptionProject {
    margin: 20px 5px;
  }

  .boxDescriptionProject h1 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
  }

  .boxDescriptionProject h2 {
    margin-bottom: 8px;
    font-size: 15px;
  }

  .boxDescriptionProject p {
    font-size: 14px;
  }

  .boxProjectImageGallery {
    padding-bottom: 10px;
  }

  .imageGalleryProject {
    max-width: 30%;
  }

  /* detailProject style ===================================================== */

}

@media only screen and (width >=993px) and (width <=1198px) {

  .cardImageContainer {
    width: 45%;
    margin: 10px;
  }

  .boxContentProject img {
    width: 90%;
  }

  .cardImageContainer img {
    height: 450px;
  }

  .boxDescriptionProject {
    margin: 30px 20px;
  }

  .cardDescription h1 {
    font-size: 18px;
  }

  .cardDescription p {
    font-size: 12px;
  }

  .buttonNavigate {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media only screen and (width >=1199px)and (width <=1399px) {
  .cardImageContainer {
    width: 30%;
    margin: 10px;
  }

  .cardImageContainer img {
    height: 450px;
  }
}

@media only screen and (width >=1400px) {
  .cardImageContainer {
    width: 30%;
    margin: 10px;
  }

  .cardImageContainer img {
    height: 450px;
  }

  .paddingModal {
    padding: 10% 20%;
  }
}

/* responsive style ===================================================== */