/* Header Course */
/* ==================================================== */
.moduleWrap {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    background-color: #102023;
    /* padding: 2% 3%; */
}

.moduleTitleHeader {
    width: 75%;
    height: 635px;
    position: relative;
    margin: 0px auto;
}

.imageHeaderModule {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 12px;
    z-index: -1;
    top: 0;
}


.titleDirection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    background-color: white;
    z-index: 10;
    width: 80%;
    max-height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 16px 16px;
    border-radius: 0px 12px 12px 12px;
    box-shadow: 3px 3px 7px 1px rgb(80, 81, 81);
}

.boxImageTitle h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.boxImageTitle h2 {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.authorBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.authorImage {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50px;
}

.authorBox p {
    font-size: 12px;
}




/* Header Course */


/* Content Course */
/* ==================================================== */

.containerContentCourse {
    /* margin: 4% 5%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    color: white;
}

.containerContentCourse ul {
    list-style-type: circle;
}

.containerContentCourse li {
    line-height: 24px;
}

.containerContentCourse li h3 {
    margin-bottom: 10px;
}

.contentCourseText {
    /* padding: 0px 1%; */
    display: flex;
    flex-direction: column;
}

.contentCourseText h1 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 16px;
}

.contentCourseText p {
    font-size: 15px;
    margin-bottom: 8px;
    display: inline;
}

.textListSize {
    font-size: 15px;
}

.courseContentSpace {
    margin-top: 4px;
}

.imageContentCourse {
    width: 1000px;
    height: auto;
    display: block;
    object-fit: cover;
    border-radius: 12px;
    margin: 20px auto;
}

.noteMark {
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
}


/* Content Course */
/* ==================================================== */

/* ==================================================== */
/* Responsive */
@media only screen and (width <=600px) {
    .containerContentCourse {
        margin: 0px;
    }

    .boxImageTitle h1 {
        font-size: 8px;
    }

    .boxImageTitle h2 {
        font-size: 14px;
    }

    .contentCourseText h1 {
        font-size: 23px;
    }


    .contentCourseText p {
        font-size: 14px;
    }

    .noteMark p {
        font-size: 14px;
    }

    .textListSize {
        font-size: 14px;
    }

}

@media only screen and (width <=1198px) {
    .containerContentCourse {
        margin: 0px;
    }

    .moduleTitleHeader {
        height: 435px;
    }

    .imageHeaderModule {
        height: 400px;
    }

    .boxImageTitle h1 {
        font-size: 20px;
    }

    .boxImageTitle h2 {
        font-size: 12px;
    }
}

@media only screen and (width >=1199px) {

    .boxImageTitle h1 {
        font-size: 20px;
    }

    .boxImageTitle h2 {
        font-size: 12px;
    }

    .contentCourseText h1 {
        font-size: 27px;
    }

    .contentCourseText p {
        font-size: 17px;
    }

    .noteMark {
        font-size: 17px;
    }

    .textListSize {
        font-size: 17px;
    }
}

/* Responsive */
/* ==================================================== */