.containerContentCourse {
    margin: 0 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapTabs {
    width: 95%;
    position: relative;
    margin: 0px auto;
}

.flexImage {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.collapseImage {
    width: 90%;
    height: 600px;
    display: block;
    object-fit: cover;
    margin: 20px auto;
}

.collapseImageTitle {
    width: 100%;
    height: 400px;
    object-fit: cover;
}


.flexImageTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.imageWithTitleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
}

.imageWithTitleBox h3 {
    margin: 12px 0px;
}

@media only screen and (width <=600px) {

    .collapseImage {
        width: 100%;
        height: 300px;
    }

}

@media only screen and (width >=601px) and (width <=950px) {

    .collapseImage {
        width: 100%;
        height: 450px;
    }

}

@media only screen and (width <=1140px) {

    .flexImage {
        flex-direction: column;
        align-items: center;
    }


    .flexImageTitle {
        flex-direction: column;
    }

}

@media only screen and (width <=1700px) {}